import { configureStore } from "@reduxjs/toolkit";
import  currentIndexReducer  from "../state/currentIndex";
import loadingReducer from "../state/loading.slice";
import navigationReducer from "../state/navigation.slice";
import windowWidthReducer from "../state/windowWidth.slice";

export const store = configureStore({
  reducer: {
    loading: loadingReducer,
    navigation: navigationReducer,
    windowWidth: windowWidthReducer,
    currentIndex: currentIndexReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",  
});
