import styled from "styled-components";

export const WrapperPreloader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  animation: ${(props) => (props.complete ? "swap 1s 1" : "")};
  animation-direction: alternate;
  animation-fill-mode: forwards;
  background-color: #fff;
  margin: 0%;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 9999;

  @keyframes swap {
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
  }

  @media (max-width: 768px) {
    width: 100vw;
    height: 100vh;

    @keyframes swap {
      from {
        height: 100%;
      }
      to {
        height: 0%;
      }
    }
  }
`;
export const PreloaderLogo = styled.img`
  animation: ${(props) => (props.complete ? "imageSwap 1s 1" : "")};
  animation-direction: alternate;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  @keyframes imageSwap {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.5) translateY(-45vw);
      opacity: 0;
    }
  }
`;
