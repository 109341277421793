import styled from 'styled-components';
import mobilebg from '../../public/image/home/menubg.png';

export const Wrapper = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  width: 80px;
  z-index: 100;
  background: white;
  transition: 0.5s;

  &.wrapper-sidebar--opened {
    width: 450px;
    max-width: 80%;

    nav {
      right: 50px;
      transition: 0.5s;
    }

    .wrapper-sidebar__sideMenu__icon:after,
    .wrapper-sidebar__sideMenu__icon:before {
      transform: rotate(90deg);
    }
  }

  .wrapper-sidebar__sideMenu {
    height: 100%;
    position: relative;
    .wrapper-sidebar__sideMenu__icon {
      position: absolute;
      height: 20px;
      width: 35px;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      cursor: pointer;

      &:before {
        content: '';
        width: 100%;
        height: 3px;
        position: absolute;
        top: 0px;
        background: #081727;
        transition: 1s;
      }
      &:after {
        content: '';
        width: 100%;
        height: 3px;
        background: #081727;
        position: absolute;
        top: 10px;
        transition: 1s;
      }
    }
  }

  span.wrapper-sidebar__sideMenu__logo {
    width: 50px;
    display: inline-block;
    left: 11px;
    position: relative;
    top: 12px;
    img {
      width: 100%;
      transition: 0.5s;
    }
  }

  nav {
    transition: 1s;
    position: absolute;
    top: 50%;
    right: 900px;
    transform: translateY(-50%);
    width: max-content;
    ul {
      list-style: none;
      font-size: var(--font-size-big);
      font-family: 'poppinsBold';
      li {
        cursor: pointer;
        border-bottom: 1px solid #d1d1d1;
        margin-right: 2rem;
        padding: 0.5rem 0;
        color: #164074;
        transition: color ease-in-out 250ms;
        :hover {
          color: #e14e1b;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }

    @media (max-width: 768px) {
      width: 350px;
      max-width: 66%;
      height: 100vh;
      background-image: url(${mobilebg.src});
      background-size: contain;
      transform: unset;
      color: white;
      position: fixed;
      top: 0;
      left: -600px;
      ul {
        padding-left: 20px;
        font-size: 1.5rem;
        li {
          padding: 1rem 0rem;
          color: #fff;
          font-size: 2rem;
          @media (max-width: 480px) {
            font-size: 1.1rem;
          }
          &:last-child {
            border-bottom: 0 !important;
          }
        }
      }
    }
  }

  &.wrapper-sidebar--mobile nav {
    top: 0;
    left: 0;
    ul li {
      color: white;
      border-bottom: 1px solid #606283;
      margin-right: 1.5rem;
    }
  }

  .wrapper-sidebar__mobileMenu {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;

    .wrapper-sidebar__mobileMenu__Logo img {
      width: 9rem;
    }

    span {
      font-size: 2.5rem;
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    width: 100vw;
    height: 75px;

    .wrapper-sidebar__sideMenu {
      height: 100%;
      display: flex;
      .wrapper-sidebar__sideMenu__logo {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
      }
    }
  }
`;
