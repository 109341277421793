import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  activeSection: 0,
  whyActive: -1,
};

export const activeSection = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setActiveSection: (state, action) => {
      state.activeSection = action.payload;
    },
    setWhyActive: (state, action) => {
      state.whyActive = action.payload;
    },
  },
});

export const {setActiveSection, setWhyActive} = activeSection.actions;
export const selectActiveSectionIndex = (state) =>
  state.navigation.activeSection;
export const selectWhyActiveSectionIndex = (state) =>
  state.navigation.whyActive;
export default activeSection.reducer;
