import React, {useState, useEffect} from 'react';
import {Wrapper} from './SideMenu.styles';
import {VscChromeClose} from 'react-icons/vsc';
import {useDispatch} from 'react-redux';
import {setActiveSection} from '../../core/state/navigation.slice';
import menuLogo from '../../public/image/Home/logo.png';
import logo from '../../public/image/Home/blackLogo.png';
import mobileLogo from '../../public/image/Home/mobileLogo.png';
import {setWindowWidth} from '../../core/state/windowWidth.slice';
import Link from 'next/link';
import {useRouter} from 'next/router';
const SideMenu = () => {
  const router = useRouter();
  const [menuStatus, setMenuStatus] = useState(false);
  const [desktop, setDesktop] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const dispatch = useDispatch();

  const sections = [
    {text: 'About Us', url: '/'},
    {text: 'The LMS Model', url: '/whyUs'},
    {text: 'Our Services', url: '/'},
    {text: 'Our Packages', url: '/'},
    {text: 'Contact Us', url: '/'},
  ];
  /**
   *
   * @param {*} sectionNumber
   * @description when we click in the menu it will scroll to the section throw the section number
   */
  const openSection = (sectionNumber) => {
    dispatch(setActiveSection(sectionNumber));
    setOpenMobileMenu(!openMobileMenu);
  };

  useEffect(() => {
    const checkDesktop = () => {
      window.innerWidth > 768 ? setDesktop(true) : setDesktop(false);
      dispatch(setWindowWidth(window.innerWidth));
      setOpenMobileMenu(false);
      setMenuStatus(false);
    };
    checkDesktop();
    window.addEventListener('resize', checkDesktop);
    return () => window.removeEventListener('resize', checkDesktop);
  }, []);

  return (
    <Wrapper
      className={
        menuStatus && desktop
          ? 'wrapper-sidebar--opened'
          : openMobileMenu && !desktop && 'wrapper-sidebar--mobile'
      }
      onMouseEnter={() => setMenuStatus(true)}
      onMouseLeave={() => setMenuStatus(false)}>
      <div className='wrapper-sidebar__sideMenu'>
        <span
          className='wrapper-sidebar__sideMenu__logo'
          onClick={() => setOpenMobileMenu(false)}>
          <Link href='/'>
            <img
              src={menuStatus && desktop ? menuLogo.src : logo.src}
              alt='logo'
              style={{
                width: menuStatus && desktop && '150px',
                cursor: 'pointer',
              }}
              onClick={() => openSection(0)}
            />
          </Link>
        </span>
        <div
          className='wrapper-sidebar__sideMenu__icon'
          onClick={() => {
            setOpenMobileMenu(!openMobileMenu);
            setMenuStatus(!menuStatus);
          }}></div>
      </div>
      <nav>
        {!desktop && (
          <div className='wrapper-sidebar__mobileMenu'>
            <div
              className='wrapper-sidebar__mobileMenu__Logo'
              onClick={() => setOpenMobileMenu(!openMobileMenu)}>
              <Link href='/'>
                <img src={mobileLogo.src} alt='mobile-logo' />
              </Link>
            </div>
            <span onClick={() => setOpenMobileMenu(false)}>
              <VscChromeClose />
            </span>
          </div>
        )}
        <ul>
          {sections.map((section, index) => (
            <li
              key={index}
              onClick={async () => {
                if (!desktop && section.url === '/whyUs') {
                  setOpenMobileMenu(false);
                  dispatch(setActiveSection(-1));
                  return;
                }
                if (router.asPath.length > 1) {
                  await router.push('/');
                }
                openSection(index + 1);
                setMenuStatus(false);
              }}>
              {desktop ? (
                section.text
              ) : (
                <Link href={section.url}>{section.text}</Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </Wrapper>
  );
};

export default SideMenu;
