import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentIndex: 0,
};

 const currentIndexSlice = createSlice({
  name: "currentIndex",
  initialState,
  reducers: {
    swipeLeft: (state) => {
      state.currentIndex = state.currentIndex - 1;
    },
    swipeRight: (state) => {
      state.currentIndex = state.currentIndex + 1;
    },
  },
});

export const { swipeLeft, swipeRight } = currentIndexSlice.actions;


export default currentIndexSlice.reducer