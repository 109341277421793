import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  windowWidth: 'desktop',
};

export const windowWidth = createSlice({
  name: 'windowWidth',
  initialState,
  reducers: {
    setWindowWidth: (state, action) => {
      if (action.payload > 768) {
        state.windowWidth = 'desktop';
      } else if (480) {
        state.windowWidth = 'tablet';
      } else {
        state.windowWidth = 'mobile';
      }
    },
  },
});

export const {setWindowWidth} = windowWidth.actions;
export const selectWindowWidth = (state) => state.windowWidth.windowWidth;
export default windowWidth.reducer;
