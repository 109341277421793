import React, {useEffect, useState} from 'react';
import {Provider} from 'react-redux';
import {useRouter} from 'next/router';
import {store} from '../core/store/store';
import SideMenu from '../components/SideMenu/SideMenu';
import Loading from '../components/Loading/Loading';
import '../styles/fonts.css';
import '../styles/globals.css';
import Head from 'next/head';
import Script from 'next/script';

function MyApp({Component, pageProps}) {
  const [completed, setCompleted] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      setCompleted(true);
    }, 2500);
  }, []);

  useEffect(() => {
    const handleStart = () => {
      setCompleted(false);
    };
    const handleStop = () => {
      setTimeout(() => {
        setCompleted(true);
      }, 2000);
    };
    const handleError = () => setCompleted(true);
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleError);
    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleError);
    };
  }, [router]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js').then(
        function (registration) {
          console.log(
            'Service Worker registration successful with scope: ',
            registration.scope
          );
        },
        function (err) {
          console.log('Service Worker registration failed: ', err);
        }
      );
    }
  }, []);

  return (
    <Provider store={store}>
      <Head>
        <title>Electa Sourcing</title>
      </Head>
      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      <Script
        src='https://www.googletagmanager.com/gtag/js?id=G-GMERQPFE5K'
        strategy='afterInteractive'
      />
      <Script id='google-analytics' strategy='afterInteractive'>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-GMERQPFE5K');
        `}
      </Script>
      <Loading completed={completed} />
      <SideMenu />
      <Component {...pageProps} />
    </Provider>
  );
}

export default MyApp;
