import React from 'react';
import {WrapperPreloader, PreloaderLogo} from './Loading.style';
import inload from '../../public/image/home/inload.png';

const Loading = ({completed}) => {
  return (
    <WrapperPreloader complete={completed}>
      <PreloaderLogo complete={completed} src={inload.src} alt='image-logo' />
    </WrapperPreloader>
  );
};

export default Loading;
